<script setup>
</script>


<template>
  <div class="">
    <slot />
  </div>
</template>


<style lang="scss" scoped>

</style>